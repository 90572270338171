<template>
  <span v-if="no" class="text-nowrap">
    <a-tooltip v-if="noType">
      <template #title>{{ $t(noType?.label) }}</template>
      <component :is="antIcons[noType?.icon]" class="text-primary" :style="noType.style" />
      {{ no }}
    </a-tooltip>
    <template v-else>{{ no }}</template>
  </span>
  <span v-else>-</span>
</template>

<script>
import { defineComponent, reactive, toRefs, watch } from 'vue';
import { Tooltip } from 'ant-design-vue';
import * as antIcons from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    ATooltip: Tooltip
  },
  props: {
    no: {
      type: String,
      default: ''
    }
  },
  setup (props) {

    const state = reactive({
      noType: null
    })
    
    const typesObject = {
      // 入库计划改为[IBS, IBD, IBT]
      // 存储仓入库编号
      "ibs": {
        label: 'warehouse.inbound_plan_of_storage_warehouse',
        icon: 'ImportOutlined',
        style: { transform: 'rotateY(180deg)' }
      },
      // 配送仓入库编号
      "ibd": {
        label: 'warehouse.inbound_plan_of_consignment_warehouse',
        icon: 'ImportOutlined',
        style: { transform: 'rotateY(180deg)' }
      },
      // 中转仓入库编号
      "ibt": {
        label: 'warehouse.inbound_plan_of_transit_warehouse',
        icon: 'ImportOutlined',
        style: { transform: 'rotateY(180deg)' }
      },
      // 入库编号
      "ib": {
        label: 'warehouse.inbound_plan',
        icon: 'ImportOutlined',
        style: { transform: 'rotateY(180deg)' }
      },
      // 中转仓出库编号
      "obt": {
        label: 'warehouse.outbound_plan_of_transit_warehouse',
        icon: 'ExportOutlined',
        style: {}
      },
      // 存储仓出库编号
      "obs": {
        label: 'warehouse.outbound_plan_of_storage_warehouse',
        icon: 'ExportOutlined',
        style: {}
      },
      // 出库编号
      "ob": {
        label: 'warehouse.outbound_plan',
        icon: 'ExportOutlined',
        style: {}
      },
      "sm": {
        label: 'warehouse.transport_plan',
        icon: 'CarOutlined',
        style: {}
      }
    }

    const getNoTypes = (value) => {
      let noType = null
      if (value) {
        let result = Object.keys(typesObject).find(item => {
          return value.toLowerCase().indexOf(item) === 0
        })
        if (result) {
          noType = typesObject[result]
        }
      }
      state.noType = noType
    }

    watch(() => props.no, getNoTypes, { immediate: true })

    return {
      ...toRefs(state),
      antIcons,
      typesObject,
      getNoTypes
    }
  }
})
</script>

<style scoped>

</style>