import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  getList: "/api/ProductService/CostLog/GetListPaged",
  getDetail: "/api/ProductService/CostLog/Get",
  exportList: "/api/ProductService/CostLog/ExportList",
  getExportStorageFeeUrl: "/api/WarehouseService/StorageFeeDetail/ExportStorageFeeDetailsByMonthWithShop",
  getStorageFeeDetail: "/api/WarehouseService/StorageFeeDetail/GetStorageFeeDetail",
  getTemporaryStorageFeeDetail:"/api/WarehouseService/RtsFeeDetail/GetRtsFeeDetail",
  exportStorageFeeDetail: "/api/WarehouseService/StorageFeeDetail/ExportStorageFeeDetail",
  exportTemporaryStorageFeeDetail:"/api/WarehouseService/RtsFeeDetail/ExportRtsFeeDetail",
  // 未结算费用清单
  getUnCompleteListPaged: "/api/ProductService/CostLog/GetUnCompleteListPaged",
  // 导出未结算费用清单
  exportUnCompleteList: "/api/ProductService/CostLog/ExportUnCompleteList",
};

export const getList = ({
  businessNo,
  transactionType,
  businessType,
  countryId,
  facilityCode,
  currencyId,
  startTime,
  endTime,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getList,
    data: {
      businessNo,
      transactionType,
      businessType,
      countryId,
      facilityCode,
      currencyId,
      startTime,
      endTime,
      skipCount,
      maxResultCount,
      sorting,
    },
    method: "POST",
  });
};

export const getDetail = (id) => {
  return client.request({
    url: Api.getDetail,
    params: { id },
    method: "get",
  });
};

export const exportList = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.exportList);
};

export const getExportStorageFeeUrl = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.getExportStorageFeeUrl);
};

export const getStorageFeeDetail = (data) => {
  return client.request({
    url: Api.getStorageFeeDetail,
    data,
    method: "POST",
  });
};

export const getTemporaryStorageFeeDetail = (data) => {
  return client.request({
    url: Api.getTemporaryStorageFeeDetail,
    data,
    method: "POST",
  });
};

export const exportStorageFeeDetail = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.exportStorageFeeDetail);
};

export const exportTemporaryStorageFeeDetail = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.exportTemporaryStorageFeeDetail);
};

export const getUnCompleteListPaged = (data) => {
  return client.request({
    url: Api.getUnCompleteListPaged,
    data,
    method: "POST",
  });
};

export const exportUnCompleteList = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.exportUnCompleteList);
};